

var animated = [];

function isFunction(functionToCheck) {
        if (typeof window[functionToCheck] === "function"){
            return true;
        }else{ 
            return false;
        }
}
            
function getPositionTop (obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            curtop = obj.offsetTop;
            while (obj = obj.offsetParent) {curtop += obj.offsetTop;}
        }
        return curtop;
}

    
function isIntop(node) {
  //var rect = node.getBoundingClientRect();
  //If window.pageYOffset
    var positionY = getPositionTop(node);
    var elementH = node.offsetHeight;
    //var scrollTop = window.pageYOffset || document.node.scrollTop;
    if( window.pageYOffset >= (positionY - 100) && window.pageYOffset <= (positionY+elementH) ){
        return true;
    }
  /*var msg = "rect.pageYOffset="+positionY+" / window.pageYOffset="+window.pageYOffset;
  console.log(msg);
  */
};
            
                
function isInViewport(node) {
  var rect = node.getBoundingClientRect()
  return (
    (rect.height > 0 || rect.width > 0) &&
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
  
};


/*-----------------------------------
*************************************

menu

*************************************
-----------------------------------*/
var ismenu = false;
function pushtoblack(){
    ismenu = true;
    $(".chngclr").removeClass("to_wht");
    $(".chngclr").addClass("to_blck");
}

    function toggl(bt, cible) {
        if ($(bt).hasClass('open')) {

            setTimeout(function() {
                $(bt + ", " + cible+"").removeClass('open');
            }, 300);
            $("body").removeClass("menuopen");
            ismenu = false;

        } else {
            $(bt + ", " + cible+"").addClass('open');
            $("body").addClass("menuopen");
            pushtoblack();
        }
    }





/*-----------------------------------
*************************************

parallax

*************************************
-----------------------------------*/
function parallaxY(element){

    var initY = $(element).offset().top;
    var scrolled = $(window).scrollTop();
    var height = $(element).height();
    var endY  = initY + $(element).height();
    var ratioo = $(element).data("speed");
    // Check if the element is in the viewport.
    var visible = isInViewport(element);
       
    if(visible) {
    
      var diff = scrolled - initY;
      var ratio = Math.round((diff / height) * 100);
  
      TweenMax.to(element, 0, {'transform':'translateY('+parseInt((ratio * ratioo)) + 'px)'});


    }
};


/*-----------------------------------
*************************************

Anim text

*************************************
-----------------------------------*/
var lunched= [];

function animetxt(id){
    
    console.log("1");
    //console.log("lunched[id]="+lunched[id]);
  if( !lunched[id]){
    //console.log(id+" est animé");
    console.log("2");
    lunched[id]= true;
    
    anime.timeline({loop: false})
    .add({
      targets: id+' .letter',
      duration: 700,
      delay: function(el, index) { return index*50; },
      easing: 'easeOutCirc',
      opacity: 1,
      translateX: function(el, index) {
        return [(50+index*10),0]
      }
    });
  }


}

/*-----------------------------------
*************************************

menu on load

*************************************
-----------------------------------*/
    $(window).on("load", function() {
        $("#lunchmenu").on("click", function() {
            toggl("#lunchmenu", "#navigation");
            
        });

    });




/*-----------------------------------
*************************************

On ready

*************************************
-----------------------------------*/

$(document).ready(function() {

$("main").fitVids();
/* prlx img BG */
  $('.prlxbg').each(function(index, element) {
   
    var img= $(this).data("image");
    var ratioo = $(element).data("speed");
    $(this).append('<img src='+img+' data-speed='+ratioo+' >');
 
  });



    /* for animate text */
    var textWrapper = document.querySelectorAll('.animtext .letters');
    textWrapper.forEach(function(item) {
      item.innerHTML = item.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
    });
    
    var textWrapper = document.querySelectorAll('.splitting');
    textWrapper.forEach(function(item) {
      item.innerHTML = item.textContent.replace(/\S/g, "<span class='char'>$&</span>");
    });

  var textWrapper = document.querySelectorAll('.splittingwords');
  textWrapper.forEach(function(item) {
    var str = item.innerHTML;
    var res = str.split(" ");
    console.log(res);
    var newcontent = "";
    res.forEach(function(word) {
      newcontent = newcontent+" <span class='letter'>"+ word +"</span>";
    });
    item.innerHTML = newcontent;
  });



});

function raf(){
        /* change color */
    var zero = document.getElementById("section1"); 
    if(isIntop(zero)){
      $(".chngclr").removeClass("to_blck");
      $(".chngclr").addClass("to_wht");
    }else{
      $(".chngclr").removeClass("to_wht");
      $(".chngclr").addClass("to_blck");
    }

   


    /*text animation */
    $('.animtext').each(function(index, element) {
  
      var id ="#"+$(this).attr("id");
      
      var visible = isInViewport(this);
      
      if( animated[id]!==true) {
      
        if(visible) {
        
          animetxt(id);
          animated[id] = true;

        }else{
            
         lunched[id]= false;
         $(id+ ' .letter').css({"opacity":0});

        }
        
      }else{
        
      }
    });
}

$(function () {
  $(document).scroll(function () {
    

    raf();



  });

  raf();
});

$(window).on('load', function() {

	$('.masonry').masonry({
	  // options
	  itemSelector: '.grid-item',
	  columnWidth: 0
	});
	
	$(window).resize(function () {
		$('.masonry').masonry('destroy');
		$('.masonry').masonry({
		  // options
		  itemSelector: '.grid-item',
		  columnWidth: 0
		});
	});
	
});

$(document).ready(function() {

    $(".cover").each(function(index) {
        if ($(this).attr("data-image")) {
            var image = $(this).data("image");
            $(this).css("background-image", "url(" + image + ")");

        }
    });

/* paralax */
    //$('.imgfirst').paroller({ factor: 0.5, factorXs: 0.2, factorSm: 0.3, type: 'foreground', direction: 'vertical' }); 
    //$('.prlx .cover').paroller({ factor: 0.1, factorXs: 0.2, factorSm: 0.3, type: 'foreground', direction: 'vertical' });  

    //$('.prlx2 .cover').paroller({ factor: 0.04, factorXs: 0.2, factorSm: 0, type: 'foreground', direction: 'vertical' });  

   // $("#main").fitVids();




    /*
    forms
    */
    if ($(".ajaxForms").length > 0) {





        /* send to ajax */
        $(".ajaxForms").submit(function(e) {
            e.preventDefault();
            Materialize.updateTextFields();


            var form = $(this);
            var cible = $(this).attr("action");

            $.post(form.attr('action'), form.serialize(), function(data) {
                //var data = $.parseJSON(data);

                if (data.status === true) {
                    console.log("ok");

                } else {
                    console.log(data.status);
                }


            });
            return false;
        });


    }

    $('.js-scrollTo').on('click', function(e) { // Au clic sur un élément
        e.preventDefault();
        var page = $(this).attr('href'); // Page cible
        var speed = 750; // Durée de l'animation (en ms)
        $('html, body').animate({ scrollTop: $(page).offset().top }, speed); // Go
        return false;
    });







});